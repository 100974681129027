import Modal from '@/components/Modal';
import { formatPrice } from '@/lib/currency';
import { ModalControl } from '@/lib/modal';
import { ClipboardListIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';

import { Product } from '../../../graphql-types';

export type ConfirmAddToQuoteModalData = { product: Product };

type ConfirmAddToQuoteModalProps = {
  control: ModalControl<ConfirmAddToQuoteModalData>;
};

const ConfirmAddToQuoteModal: FC<ConfirmAddToQuoteModalProps> = ({
  control,
}) => {
  return (
    <>
      <Modal
        control={control}
        title="Produsul a fost adaugat in oferta"
        renderActions={(props) => (
          <>
            <button
              {...props}
              className={clsx(props.className, 'bg-yellow font-semibold')}
              type="button"
              onClick={() => navigate('/ro/oferta-mea/')}
            >
              <ClipboardListIcon className="w-5 h-5 mr-2 lg:mr-4" />
              Mergi la oferta
            </button>
            <button
              {...props}
              className={clsx(props.className, 'ring ring-gray-250 opacity-60')}
              type="button"
              onClick={() => control.close()}
            >
              Ok
            </button>
          </>
        )}
      >
        {control.data && (
          <div
            className={clsx(
              'flex gap-4 my-6 bg-gray-250 -mx-4 px-4 lg:-mx-14 lg:px-14 py-2'
            )}
          >
            <GatsbyImage
              className="w-14 h-14 border-gray-250"
              alt={control.data.product.name}
              image={getImage(control.data.product.image as any)}
              objectFit="contain"
            />
            <div className="flex-1 flex flex-col min-w-0">
              <div className="text-base text-gray-500 lg:text-primary-300 lg:font-semibold opacity-85 lg:opacity-100 truncate lg:whitespace-normal">
                {control.data.product.name}
              </div>
              <div className="text-xs opacity-50 truncate lg:flex-1">
                {control.data.product.code}
              </div>
              <div className="flex gap-1">
                <div className="font-semibold text-red">
                  {formatPrice(
                    control.data.product.averageMinPrice.value,
                    control.data.product.averageMinPrice.currency,
                    false,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )}
                  {' - '}
                  {formatPrice(
                    control.data.product.averageMaxPrice.value,
                    control.data.product.averageMaxPrice.currency,
                    false,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ConfirmAddToQuoteModal;
