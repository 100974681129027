import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import IntInput from '@/components/form/IntInput';
import ConfirmAddToQuoteModal, {
  ConfirmAddToQuoteModalData,
} from '@/components/product/ConfirmAddToQuoteModal';
import ExplanatoryBanner from '@/components/product/ExplanatoryBanner';
import SocialProofBar from '@/components/product/SocialProofBar';
import SupplierCardList from '@/components/supplier/SupplierCardList';
import { MissingImageIcon } from '@/images/product';
import { formatPrice } from '@/lib/currency';
import { useModal } from '@/lib/modal';
import { algoliaAnalytics } from '@/lib/search';
import { ShoppingBasketContext } from '@/lib/shoppingBasket';
import { getUserID } from '@/lib/tracking';
import {
  ChevronRightIcon,
  ClipboardListIcon,
  HomeIcon,
  InformationCircleIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { graphql, Link, PageProps } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { FC, Fragment, useContext } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

const Product: FC<PageProps<any>> = ({ data: { product } }) => {
  const {
    path,
    code,
    name,
    description,
    image,
    price,
    averageMinPrice,
    averageMaxPrice,
    specifications,
    accessories,
    categories,
    suppliers,
  } = product;
  const [queryID] = useQueryParam('qid', StringParam);
  const { addToBasket } = useContext(ShoppingBasketContext);

  const confirmAddToQuoteModalControl = useModal<ConfirmAddToQuoteModalData>();

  const formik = useFormik({
    initialValues: {
      quantity: 1,
    },
    onSubmit: async ({ quantity }, { setSubmitting, resetForm }) => {
      setSubmitting(true);

      await addToBasket({
        product,
        quantity: Math.max(1, quantity),
        resellers: suppliers.map((it) => it.name),
        queryId: queryID,
      });

      try {
        algoliaAnalytics('convertedObjectIDsAfterSearch', {
          index: process.env.GATSBY_ALGOLIA_PRODUCTS_INDEX_NAME,
          eventName: 'Add To Basket',
          queryID,
          objectIDs: [path],
          userToken: getUserID(),
        });
      } catch {
        // ata ete, n ai csf
      }

      await resetForm({ values: { quantity: 1 } });
      setSubmitting(false);
      confirmAddToQuoteModalControl.open({ product });
    },
  });

  return (
    <Layout fsoHeader minimalFooter mainClassName="relative min-h-screen">
      <SEO
        title={name}
        description={description}
        url={path}
        image={
          image?.childImageSharp
            ? {
                src: image.childImageSharp.gatsbyImageData.images.fallback.src,
                width: image.childImageSharp.gatsbyImageData.width,
                height: image.childImageSharp.gatsbyImageData.height,
              }
            : undefined
        }
        product={product}
        lang="ro"
      />

      <ExplanatoryBanner />

      <form
        onSubmit={formik.handleSubmit}
        className="w-full max-w-5xl m-auto px-4 pt-6 grid grid-cols-1 lg:grid-cols-2 gap-x-10"
        style={{ gridTemplateRows: 'repeat(9, auto) 1fr' }}
      >
        {!!categories.length && (
          <section className="row-start-1 lg:col-span-2 flex flex-wrap items-center gap-2 pb-4 text-gray-500 opacity-60">
            <Link to="/ro/cauta-produse/">
              <HomeIcon className="h-6" />
            </Link>
            {[...categories[0].breadcrumbs, categories[0]].map(
              (parentCategory) => (
                <Fragment key={parentCategory.qualifiedName}>
                  <ChevronRightIcon className="h-3" />
                  <Link to={parentCategory.path}>{parentCategory.name}</Link>
                </Fragment>
              )
            )}
          </section>
        )}

        <section className="lg:col-start-2 lg:row-start-2">
          {/* name */}
          <div className="font-semibold lg:font-bold text-base lg:text-3xl text-primary-300">
            {name}
          </div>
        </section>

        <section className="lg:col-start-2 lg:row-start-8 lg:py-4 text-xs lg:text-base text-gray-500 lg:border-t border-gray-250">
          {/* code */}
          <div className="mt-1">
            <span className="opacity-60">Cod:</span>
            <span className="font-semibold ml-1">{code}</span>
          </div>
          {/* category */}
          {!!categories.length && (
            <div className="mt-1 mb-4 lg:m-0">
              <span className="opacity-60">Categorie:</span>
              <span className="font-semibold ml-1">
                {categories.map((it) => it.displayName).join(', ')}
              </span>
            </div>
          )}
        </section>

        <section className="lg:col-start-1 lg:row-start-2 lg:row-span-8">
          {/* image carousel */}
          <div className="h-96 lg:h-auto">
            {image?.childImageSharp ? (
              <GatsbyImage
                className="w-full max-h-full mb-4"
                alt={name}
                image={getImage(image)}
                objectFit="contain"
              />
            ) : (
              <MissingImageIcon className="w-full max-h-full text-gray-250" />
            )}
          </div>
        </section>

        <section className="lg:col-start-2 lg:row-start-3 mt-6">
          {/* price */}
          <div className="flex flex-col items-center lg:items-start gap-1">
            <div className="text-gray-500 opacity-40 text-base text-center">
              Vandut de obicei intre:
            </div>
            <div className="font-semibold text-red text-2xl lg:text-3xl">
              {formatPrice(
                averageMinPrice.value,
                averageMinPrice.currency,
                false,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )}
              {' - '}
              {formatPrice(
                averageMaxPrice.value,
                averageMaxPrice.currency,
                false,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }
              )}
            </div>
          </div>
        </section>

        <section className="lg:col-start-2 lg:row-start-5 bg-primary-100 bg-opacity-5 text-primary mt-2 mb-4 -mx-4 lg:mx-0 lg:-mt-4 px-4 py-2 flex gap-3">
          <div className="flex items-center justify-center">
            <InformationCircleIcon className="h-5 w-5" />
          </div>
          <div>
            <div>Pret Recomandat de Producator</div>
            <div className="font-semibold">
              {formatPrice(price.value, price.currency)}
            </div>
          </div>
        </section>

        <section className="lg:col-start-2 lg:row-start-6 py-2">
          * Intervalul de pret este aproximat din date istorice. Pentru oferte
          complexe, discounturile pot depasi semnificativ pretul afisat.
        </section>

        <Link
          className={clsx(
            'text-base text-gray-500 opacity-50 cursor-pointer underline',
            'text-center lg:text-left lg:col-start-2 lg:row-start-7 mb-4'
          )}
          type="submit"
          to="#suppliers"
        >
          {suppliers.length} Furnizori disponibili
        </Link>

        {description && (
          <section className="lg:col-start-2 lg:row-start-8 lg:border-t border-gray-250 lg:py-4">
            {/* description */}
            <>
              <div className="font-semibold text-base text-gray-500 mt-6 lg:mt-0 mb-2">
                Descriere
              </div>
              <div>
                {description
                  .split('\n')
                  .map((it, idx) => ({ it, idx }))
                  .map(({ it, idx }) => (
                    <p key={idx} className="mb-2">
                      {it}
                    </p>
                  ))}
              </div>
            </>
          </section>
        )}

        <div className="hidden lg:block lg:col-start-2 lg:row-start-10"> </div>

        <section className="lg:col-span-2">
          {/* specifications */}
          {!!specifications.length && (
            <>
              <div className="font-semibold text-base text-gray-500 mt-14 mb-2 lg:mb-4">
                Specificatii
              </div>
              {specifications
                .filter((spec) => spec.value !== null)
                .map((spec, idx) => ({ spec, idx }))
                .map(({ spec, idx }) => (
                  <div
                    key={idx}
                    className={clsx(
                      'flex py-3 -mx-4 lg:mx-0 px-4 lg:px-6',
                      !(idx % 2) && 'bg-primary-100 bg-opacity-5'
                    )}
                  >
                    <div className="flex-1 opacity-50">{spec.name}:</div>
                    <div className="flex-1 lg:flex-2 opacity-90">
                      {spec.value}
                      {spec.unit && ` (${spec.unit})`}
                    </div>
                  </div>
                ))}
            </>
          )}
        </section>

        <section className="lg:col-span-2">
          {/* accessories */}
          {!!accessories.length && (
            <>
              <div className="font-semibold text-base text-gray-500 mt-14 mb-6">
                Accessorii Recomandate
              </div>
              <div
                className="flex flex-col lg:grid justify-between lg:gap-10"
                style={{ gridTemplateColumns: 'repeat(auto-fill, 11rem)' }}
              >
                {accessories
                  .map((acc, idx) => ({ acc, idx }))
                  .map(({ acc, idx }, _idx, self) => (
                    <Fragment key={idx}>
                      <Link
                        to={acc.path}
                        className={clsx('flex gap-4 my-3 lg:w-44 lg:flex-col')}
                      >
                        {acc.image?.childImageSharp ? (
                          <GatsbyImage
                            className="w-14 h-14 lg:w-44 lg:h-44 lg:border border-gray-250"
                            alt={acc.name}
                            image={getImage(acc.image)}
                            objectFit="contain"
                          />
                        ) : (
                          <MissingImageIcon className="w-14 h-14 lg:w-44 lg:h-44 text-gray-250" />
                        )}
                        <div className="flex-1 flex flex-col min-w-0">
                          <div className="text-gray-500 lg:text-primary-300 lg:font-semibold opacity-85 lg:opacity-100 truncate lg:whitespace-normal">
                            {acc.name}
                          </div>
                          <div className="text-xs opacity-50 truncate lg:flex-1">
                            {acc.code}
                          </div>
                          <div className="text-base mt-2">
                            <div className="font-semibold text-red">
                              {formatPrice(
                                acc.averageMinPrice.value,
                                acc.averageMinPrice.currency,
                                false,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }
                              )}
                              {' - '}
                              {formatPrice(
                                acc.averageMaxPrice.value,
                                acc.averageMaxPrice.currency,
                                false,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </Link>
                      {_idx + 1 < self.length && (
                        <hr className="lg:hidden border-t border-primary-100 -mx-4 opacity-10" />
                      )}
                    </Fragment>
                  ))}
              </div>
            </>
          )}
        </section>

        <section id="suppliers" className="lg:col-span-2 lg:mb-16">
          <h2 className="mt-10 mb-4 font-semibold text-base">Vandut de</h2>

          <SupplierCardList suppliers={suppliers} />
        </section>

        <section className="sticky lg:static bottom-0 mt-6 lg:mb-4 lg:mt-2 lg:col-start-2 lg:row-start-4">
          {/* button */}
          <div
            style={{ gridTemplateColumns: '120px 1fr 1fr' }}
            className="py-3 lg:pb-4 border-t border-gray-250 lg:border-t-0 -mx-4 px-4 bg-white grid grid-cols-3 gap-4 items-center"
          >
            <IntInput
              className=""
              innerClassName="h-10"
              inputClassName="font-semibold text-center"
              formik={formik}
              name="quantity"
            />
            <button
              id="addToQuote"
              type="submit"
              className="col-span-2 w-full lg:w-auto flex items-center justify-center px-3 lg:px-8 py-2 border border-transparent rounded shadow-sm text-base font-semibold bg-yellow hover:bg-yellow"
            >
              <ClipboardListIcon className="w-5 h-5 mr-2 lg:mr-4" />
              Adauga in oferta
            </button>
          </div>
        </section>
      </form>

      <SocialProofBar className="opacity-50 my-6" withLinks={false} />

      <ConfirmAddToQuoteModal control={confirmAddToQuoteModalControl} />
    </Layout>
  );
};

export const query = graphql`
  query Product($id: String!) {
    product(id: { eq: $id }) {
      path
      name
      code
      image {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      price {
        value
        currency
      }
      averageMinPrice {
        value
        currency
      }
      averageMaxPrice {
        value
        currency
      }
      description
      specifications {
        name
        unit
        value
      }
      accessories {
        path
        name
        code
        averageMinPrice {
          value
          currency
        }
        averageMaxPrice {
          value
          currency
        }
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      categories {
        name
        displayName
        path
        qualifiedName
        breadcrumbs {
          name
          qualifiedName
          path
        }
      }
      suppliers {
        path
        name
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        tags
        areaServed
        category
      }
    }
  }
`;

export default Product;
